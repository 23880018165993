.card {
    position: absolute;
    width: 370px;
    height: 457px;
    background: linear-gradient(180deg, rgba(21, 20, 28, 0.996078) 0%, #37298E 100%);
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 20px 20px;
    box-sizing: border-box;
    z-index: 10;
    margin-top: -18px;
    margin-left: 800px;
  }
  
  .caption {
    color: #fff;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    margin-bottom: 20px;
  }
  
  .outerCircle {
    position: relative;
    width: 250px;
    height: 241.94px;
    opacity: 0.4;
    border: 7px solid #6B56FA;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .middleCircle {
    width: 218.06px;
    height: 211.02px;
    background: #6B56FA;
    opacity: 0.4;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .innerCircle {
    position: relative;
    width: 190.28px;
    height: 184.14px;
    background: #29234F;
    opacity: 0.8;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .smallCircle {
    box-sizing: border-box;
    position: absolute;
    width: 65px;
    height: 65px;
    opacity: 1;
    border: 2px solid #FFFFFF;  /* adjust border thickness as needed */
    background: transparent;
    border-radius: 50%;
    display: flex;        
    align-items: center;   
    justify-content: center; 
  }

  .playButton {
    position: absolute;
    width: 31.43px;
    height: 31.43px;
  }

  .rectangleContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
  }
  
  .rectangle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48%; /* each rectangle takes up roughly half the container width */
    height: 50px;
    background-color: #29234F;
    color: #FFFFFF;
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    cursor: pointer;
  }

  .rectangle1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48%;
    height: 50px;
    background: #FFFFFF;
    opacity: 1;
    border-radius: 11px;
    color: #000000;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  
  .rectangle2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48%; 
    height: 50px;
    background-color: #29234F;
    color: #FFFFFF;
    border-radius: 11px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 23px;
    background: #0F1020;
    opacity: 0.7;
  }
  
  .alarmIcon {
    margin-right: 10px;
    fill: #fff;
  }

  .hrectangleContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
  }
  
  .hrectangle {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 221px;
    height: 30px;
    background-color: #29234F;
    color: #FFFFFF;
    border-radius: 11px;
    margin-top: 10px;
  }
  
  .icon {
    cursor: pointer;
  }

  .asmallCircle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    width: 1.03963rem;
    height: 1.00606rem;
    border-radius: 1.03963rem;
    background: #ffffff;
    /* ... (other properties) */
  }