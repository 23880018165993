.weatherFavorite {
    width: 400px;
    height: 323px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 10;
    box-shadow: 0px 4px 10px 5px rgba(0, 0, 0, 0.5);
    border-radius: 13px;
    background: url('../rectangles2/weatherfav.png');
    color: white ;
    margin-top: -18px;
    margin-left: 800px;
}

.weatherFavorite::before {
    content: '';
    position: absolute;
    border-radius: 13px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.7; /* Adjust this value to get the desired darkness */
    z-index: 11;
}

.weatherEmoji {
    font-size: 3em; /* adjust as needed */
    z-index: 12;
}


.title {
    z-index: 12;
    margin-bottom: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #FFFFFF;
}

.iconGrid {
    z-index: 12;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.iconContainer {
    z-index: 12;
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 13px;
}

.iconContainer:hover {
    z-index: 12;
    background-color: #ddd;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
}

.iconContainer.selected {
    z-index: 12;
    background-color: #ddd;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
}

.icon {
    z-index: 12;
    width: 50px;
    height: 50px;
}
