//
//  Brand
//

@use 'sass:map';

.brand {
	display: flex;
	height: map.get($header, height);
	align-items: center;
	justify-content: space-between;
	color: color-contrast(shadow-color(map.get($aside, background-color)));

	@include aside-close {
		justify-content: center;
	}

	&-logo {
		@include aside-close {
			display: none;
		}
	}

	&-title {
		@include font-size(1.5rem);

		margin: 0;
		background-color: transparent;
		color: color-contrast(shadow-color(map.get($aside, background-color)));
		font-weight: 900;

		a {
			background-color: inherit;
			color: inherit;
			text-decoration: none;
		}
	}

	// &-shadow-color {
	//    $bg-color: shade-color(map-get($aside, background-color), 10%);
	//
	//    background-color: $bg-color;
	//    color: color-contrast($bg-color);
	// }

	&-aside-toggle {
		@include font-size(2rem);

		padding: 0.375rem;
		border-radius: 2rem;
		cursor: pointer;
		line-height: 0;

		// Mobile Status
		@include media-breakpoint-down(map-get($aside, mobile-breakpoint)) {
			display: none;
		}

		&:hover {
			background-color: auto-shade-tint(map-deep-get($aside, background-color), 8%);
		}

		&:focus {
			box-shadow: none;
		}

		svg {
			color: color-contrast(shadow-color(map.get($aside, background-color)));
		}
	}

	&-aside-toggle-close {
		@at-root .aside:not(.open) & {
			display: none;
		}
	}

	&-aside-toggle-open {
		@at-root .aside.open & {
			display: none;
		}
	}
}
