.talkAboutTask {
    box-sizing: border-box;
    position: absolute;
    width: 24.6875rem;
    height: 33.3125rem;
    background: #FAFAFA;
    border: 5px solid #6C5DD3;
    border-radius: 13px;
    /* Add any additional styles you need */
    z-index: 10;
    margin-top: -18px;
    margin-left: 800px;
  }

.title {
    margin: 20px 0 10px 20px;
    font-size: 1.5em;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #4B3195;
}

.titleUnderline {
  width: 21.40538rem;
  height: 0.0625rem;
  background-color: gray;
  margin: 0 auto; /* Center the underline */
  border: 1px solid #D9D9D9;
}

.rectangle, .rectangleLarge {
  width: 18.47981rem;
  height: 1.6875rem; 
  background: #ECEDEF;
  border-radius: 7px;
  margin: 15px 0 2px 20px; 
  display: flex;  
  align-items: center;  
  justify-content: space-between;   
  color: #000;  
  text-align: left;
  padding: 5px;
}


.rectangleLarge {
  height: 82px;
}

.iconWithText {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 1px 0 4px 20px; 
}

.icon {
  margin-right: 1px;  
  color: black;
  font-size: 16px;
}

.arrowIcon {
  cursor: pointer;
  font-size: 20px;
}


.expandedRectangle {
  position: absolute;
  width: 18.47981rem;
  height: 4.6875rem;
  background: #ECEDEF;
  border-radius: 7px;
  margin-top: 0px; /* Add some space between the rectangles */
  margin-left: 60px;
  padding: 1px; /* Add padding to the expanded rectangle */
  z-index: 10;
}

.option {
  cursor: pointer;
  padding: 5px;
  margin-bottom: 5px; /* Add space between the options */
}

.smallRectangleContainer {
  display: flex;
  padding: 1px 60px;; /* Add some space around the rectangles */
  justify-content: flex-start;
  align-items: center;
}

.smallRectangle {
  cursor: pointer;
  width: 4.29925rem;
  height: 1.37975rem;
  border: 1px solid #999999;
  border-radius: 6px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 10px;
}

.smallRectangle:not(:first-child) {
  margin-left: 10px; /* Add margin to create space between small rectangles */
}

.selectedRectangle {
  background-color: gray;
  color: #ECEDEF;
}

.buttonContainer {
    display: flex;
    justify-content: center; /* Center the buttons horizontally */
    align-items: flex-end; /* Align the buttons to the bottom */
    margin-top: 2px; /* Push the buttons to the bottom */
  }

.cancelButton {
    background: none;
    border: none;
    color: #000000;
    font-size: 1em;
    width: 11.05944rem;
    height: 2.12963rem;
    margin-top: 2px;
}

.okButton {
    background: none;
    color: black;
    font-size: 1em;
    border: 1px solid #232328;
    border-radius: 13px;
    width: 11.05944rem;
    height: 2.12963rem;
    box-sizing: border-box;
    margin-top: 2px;
}
  