.dueDateComponent {
    position: absolute;
    width: 205px;
    height: 162px;
    margin-top: 265px;
    margin-left: 420px;
    background: #392769;
    border: 5px solid #6C5DD3;
    border-radius: 13px;
    color: #ffffff;
    /* box-sizing: border-box; */
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
}

.title {
    margin: 7px 0 0 0;
    font-size: 1.5em;
    text-align: center;
}

.midTitle {
    margin: 15px 0 0 0;
    font-size: 1.2em;
    text-align: center;
    position: relative;
}

.midTitle::after {
    content: "";
    position: absolute;
    bottom: -4px; /* Adjust the positioning of the line */
    left: 50%; /* Position the line in the center */
    transform: translateX(-50%); /* Center the line */
    width: 50%; /* Adjust the width of the line */
    height: 0.7px; /* Adjust the thickness of the line */
    background-color: #ffffff; /* Set the color of the line */
}

.options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-top: 6px;
}

.btnOption {
    background: none;
    border: none;
    color: #ffffff;
    padding: 0 0 0 30px;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    margin-top: 6px;
}

.rescheduleComponent {
    position: absolute;
    width: 212px;
    height: 290px;
    margin-top: 200px;
    margin-left: 400px;
    background: #392769;
    border: 5px solid #6C5DD3;
    border-radius: 13px;
    color: #ffffff;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    text-align: center;
}

.textWithPadding {
    padding: 10px; /* Add space around the text */
    margin-top: 10px;
}

.dueDateText {
    margin-top: 16px; /* Adjust the top margin to move the due date text down */
}


/* .inputContainer {
    display: flex;
    justify-content: space-around;
    padding: 0 10px;
    width: 100%;
    flex-direction: row;
    gap: 10%;
} */

.inputContainer {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    margin-top: 20px;
  }

.inputBoxContainer {
    width: 45%;
}

.iconContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
  
.inputBox {
    border: 2px solid #6C5DD3;
    border-radius: 5px;
    padding: 5px;
    margin-right: 10px;
    width: 70%;
    box-sizing: border-box;
    margin: 0;
}

.icon {
    color: #ffffff;
    cursor: pointer;
    font-size: 1.5em;
    margin-left: 10px;
    margin: 0 10px;
}
