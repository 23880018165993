.root {
    width: 23.4375rem;
    height: 37.125rem;
    background: #1F133F;
    border-radius: 13px;
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 40px;
    color: white;
    overflow: hidden;
    margin-top: -18px;
    margin-left: 800px;
  }
  
  .rectangle {
    position: relative;
    top: 0%;
    border-radius: 13px;
    width: 375px;
    height: 57px;
    background: #392769;
    color: white;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    margin-top: -40px;
  }
  
  .oval {
    width: 2.45881rem;
    height: 3.5625rem;
    border-radius: 24px;
    background: #6C5DD3;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 10px;
  }
  
  .ring {
    width: 11rem;
    height: 11rem;
    background: #1D113D;
    border: 8px solid #1F133F;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    margin-bottom: 10px;
  }
  
  .ring.black {
    border-color: black;
  }
  
  .ring.green {
    border-color: green;
  }

  .controls {
    display: flex;
    justify-content: center;
    width: 200px;
    margin-top: 1px;
}

  .controlButton {
      background: transparent;
      border: none;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
      transition: background 0.3s ease;
      margin-bottom: 30px;
      border-radius: 13px;
  }

  .controlButton:hover {
    background: #392769;
    backdrop-filter: blur(20px);
    border-radius: 13px;
  }

  .controlButton:active,
  .active {
    background: #392769;
    backdrop-filter: blur(20px);
    border-radius: 13px;
  }

  .finishedTitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: white;
    text-align: left;
    width: 100%;
    padding-left: 15px;
    margin-top: -25px;
    margin-bottom: 5px;
  }
  
  .task {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
    border: 2px solid white;
    border-radius: 8px;
    padding: 10px;
    width: 20.6875rem;
    height: 3.125rem;
  }

  .taskRing {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid white;
    margin-right: 10px;
    flex-shrink: 0;
  }

  .completed .taskRing {
    background: #6C5DD3;
    border-color: transparent;
  }

  .checkIcon {
    color: white;
    margin-right: 10px;
  }

  .taskTitle {
    color: white;
  }

  .progressSvg {
    width: 100%;
    height: 100%;
    /* rotate to start from top */
  }



  
  