.focusBox {
    position: absolute;
    width: 350px;
    padding: 1rem;
    border: 1px solid #FF6347;
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    cursor: move; /* Indicate that the box is draggable */
    transition: width 0.3s, height 0.3s;
}

.expanded {
    top: 100px;
    width: 97.3rem;
    height: 51.3rem;
    /* cursor:none; */
}

.header {
    display: flex;
    justify-content: flex-end;
}

.leftButtons {
    display: flex;
    gap: 0.5rem;
}

.menuButton, .expandButton, .closeButton {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.2rem;
}

.menu {
    position: absolute;
    top: 3rem;
    right: 100px;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1001;
}

.menuItem {
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background 0.2s;
}

.menuItem:hover {
    background: #f0f0f0;
}

.themeMenu {
    position: absolute;
    top: 3.5rem;
    left: 0;
    background: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1001;
    padding: 1rem;
}

.themeSection {
    margin-bottom: 1rem;
}

.colorList, .wallpaperList {
    display: flex;
    gap: 0.5rem;
    flex-wrap: wrap;
}

.colorBox, .wallpaperBox {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    cursor: pointer;
    border: 1px solid #ddd;
}

.colorBox:hover, .wallpaperBox:hover {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.timer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.timerDisplay {
    font-size: 2rem;
}

.buttonStop {
    width: 5rem;
    height: 2rem;
    border-radius: 1rem;
    border: 1px solid #FF6347;
    background: rgba(255, 99, 71, 0.10);
}

.buttonStart:hover, .buttonStop:hover {
    background: rgba(255, 99, 71, 0.2);
    border-color: #ff4500;
}

.onlineUsers {
    margin-top: 1rem;
}

.onlineUsers h3 {
    margin-bottom: 0.5rem;
}

.userList {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
}

.user {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    text-align: center;
}

.userAvatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #4caf50; /* Green ring for online status */
}

.userName {
    font-weight: bold;
    font-size: 0.9rem;
}

.userPomodoro {
    font-size: 0.8rem;
    color: #555;
}

.userPomodorosCompleted {
    font-size: 0.8rem;
    color: #555;
}

.pomodoroCategories {
    margin-top: 1rem;
}

.pomodoroCategories h5 {
    margin-bottom: 0.5rem;
}

.categoryList {
    display: flex;
    gap: 1rem;
}

.categoryBox {
    flex: 1;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    text-align: center;
    cursor: pointer;
    position: relative;
    background-color: #f9f9f9;
    transition: background-color 0.3s, box-shadow 0.3s;
}

.categoryBox:hover {
    background-color: #e6e6e6;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.categoryBox::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 0.5rem;
    border-radius: 4px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
    white-space: nowrap;
}

.categoryBox:hover::after {
    opacity: 1;
}

.categoryBox span {
    font-size: 1rem;
    font-weight: bold;
}

.settingsForm {
    z-index: 1001;
    padding: 1rem;
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    position: absolute;
    left: 50%;
    top: 20%;
    transform: translateX(-50%);
}

.settingsForm label {
    display: block;
    margin-bottom: 0.5rem;
}

.settingsForm input {
    margin-left: 1rem;
}

.userStats {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
    padding: 1rem;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.statItem {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.statItem:hover {
    transform: translateY(-5px);
}

.statIcon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
}

.statContent {
    display: flex;
    flex-direction: column;
}

.statValue {
    font-size: 1.2rem;
    font-weight: bold;
    color: #FF6347;
}

.statLabel {
    font-size: 0.8rem;
    color: #666;
}