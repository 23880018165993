.weatherSuggestion {
    background: url('../rectangles2/weathersug.png');
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.25);
    border-radius: 13px;
    width: 400px;
    height: 323px;
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 40px;
    color: #fff;
    margin-top: -18px;
    margin-left: 800px;
  }

  .weatherSuggestion::before {
    content: '';
    position: absolute;
    border-radius: 13px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5; /* Adjust this value to get the desired darkness */
    z-index: 11;
}
  
  .title {
    z-index: 12;
    color: #fff;
    margin-bottom: 5px;
    margin-top: -20px;
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
  }
  
  .rectangle {
    z-index: 12;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    border-radius: 13px;
    width: 359px;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    margin-top: 20px;
    padding-left: 20px;
  }
  
  .cloudIcon {
    z-index: 12;
    transform: scale(2.2);
    color: #fff;
    margin-top: 20px;
  }
  