.chain {
  position: absolute;
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 328px;
  height: 456px;
  background: #1F133F;
  border: 5px solid #6C5DD3;
  border-radius: 13px;
  padding: 20px;
  color: white;
  margin-top: -18px;
  margin-left: 800px;
}

.title {
  margin-bottom: 20px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
}

.inputContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 15px;
}

.inputText {
  font-family: 'Poppins';
  font-style: normal;
  color: white;
  width: 159px;
  height: 20px;
  border: 1px solid #D9D9D9;
  border-radius: 13px;
  padding: 5px;
  margin-bottom: 10px;
  margin-right: 10px;
  background: none;
}

.inputNumber {
  font-family: 'Poppins';
  font-style: normal;
  color: white;
  width: 69px;
  height: 20px;
  border: 1px solid #D9D9D9;
  border-radius: 13px;
  padding: 5px;
  margin-bottom: 10px;
  margin-left: 10px;
  background: none;
}

.circleContainer {
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(6, 1fr);
  gap: 10px;
  overflow-y: scroll; /* Add a scrollbar when the content grows */
  max-height: 300px; /* Adjust to your preferred maximum height */
}

.circle {
  position: relative;
  width: 30px;
  height: 30px;
  background: white;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.selected {
  background: #68B42E;
}

.line {
  position: absolute;
  height: 2px;
  background-color: rgb(255, 255, 255); /* Choose your own color */
  transform-origin: 0% 50%;
}


