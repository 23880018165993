.calendarRedLine {
    margin-top: 218px;
    margin-left: 740px;
    width: 176px;
    height: 200px;
    background: #1F133F;
    box-shadow: 0px 20.8px 39px rgba(0, 0, 0, 0.1);
    border-radius: 13px;
    position: absolute;
    z-index: 11;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .calendarRedLine::before {
    content: "";
    display: block;
    width: 192px;
    height: 1px;
    background: #FF0000;
    position: absolute;
    top: -1px;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .calendarText {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    color: #FFFFFF;
    margin-bottom: 4px; 
    margin-left: -32px; 
  }
  
  .secondText {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    color: #FFFFFF;
  }

  .timeInputContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 10px 0;  /* Adjust as needed */
  }

    .timeInput { 
    background: none;
    border: none;
    color: #FFFFFF;
    text-align: center;
    margin: 0; 
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    width: 45%;
    }

    .timeInput:focus {
    outline: none;
    }

    .line {
        width: 80%;
        height: 1px;
        background: #FFFFFF;
        margin-top: 0px;  /* Adjust as needed */
        margin-bottom: 0px;  /* Adjust as needed */
      }

    
.timeOptions {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
  }

  .timeOptions::before {
    content: " or avaible hours ";
    display: block;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    text-align: center;
    position:absolute;
    top: -15px;  /* Adjust this value as needed */
    left: 0;
    right: 0;
}
  
  .timeOption {
    border: 1px solid #FFFFFF;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 8px;
    padding: 5px;
    text-align: center;
    cursor: pointer;
    width: 20%;
    height: 14.49px;
    border-radius: 13px;
    align-items: center;
    display: flex;
    justify-content: center;
  }
  
  .timeOption:hover, .timeOption.selected {
    background-color: #FFFFFF;
    color: #1F133F;
  }