/* BreathAnimation.module.css */
@keyframes moveAround {
  0% {
    transform: rotate(0deg) translateX(50%) rotate(0deg);
  }
  100% {
    transform: rotate(360deg) translateX(50%) rotate(-360deg);
  }
}

.movingCircle {
  animation: moveAround 8s linear infinite;
}


  