.greeting {
    margin-top: 5px;
    color:  #323232;
}

.greeting h4 {
  color: #999;
  margin-top: -10px;
}

.buttonBreath, .buttonChain, .buttonPomodoro, .buttonWeather, .buttonWeathers, .buttonDueDate, .buttonTask, .buttonRedLine {
    position: relative;
    margin-top: -90px;
    margin-left: -10px;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 6.25rem;
    border: 1px solid #44DB25;
    background: rgba(110, 182, 92, 0.20);
}

.buttonBreath {
    margin-left: 850px;
    border: 1px solid #44DB25;
    background: rgba(110, 182, 92, 0.20);
}
.buttonBreath:hover {
    background: rgba(110, 182, 92, 0.30);
    border-color: #36a020;
  }

.buttonChain {
    border: 1px solid #0085FF;
    background: rgba(108, 191, 204, 0.2);
}
.buttonChain:hover {
    background: rgba(108, 191, 204, 0.3);
    border-color: #006fdd;
  }

.buttonPomodoro {
    border: 1px solid #F17BBB;
    background: rgba(241, 123, 187, 0.10);
}
.buttonPomodoro:hover {
    background: rgba(241, 123, 187, 0.2);
    border-color: #e011a4;
  }

.buttonWeather {
    border: 1px solid #1AE6C7;
    background: rgba(4, 162, 135, 0.12);
}
.buttonWeather:hover {
    background: rgba(4, 162, 135, 0.2);
    border-color: #12d0b0;
  }

.buttonWeatherHome {
    padding-top: 1px;
    height: 20px;
    margin-top: -63px;
    margin-left: 900px;
    border: 1px solid #1AE6C7;
}

.buttonWeatherFav {
    padding-top: 1px;
    height: 20px;
    margin-top: -63px;
    border: 1px solid #1AE6C7;
}

.buttonWeatherSug {
    padding-top: 1px;
    height: 20px;
    margin-top: -63px;
    border: 1px solid #1AE6C7;
}

.buttonTask {
    border: 1px solid #44DB25;
    background: rgba(110, 182, 92, 0.20);
}

.buttonDueDate {
    border: 1px solid #FFD700;
    background: rgba(255, 215, 0, 0.12);
}

.buttonRedLine {
    border: 1px solid #8478F9;
    background: rgba(132, 120, 249, 0.12);
}
.buttonRedLine:hover {
    background: rgba(132, 120, 249, 0.2);
    border-color: #7266e4;
  }

.icon {
    margin-left: -5px;
    width: auto;  /* or a specific size */
    height: 100%; /* or a specific size */
  }


  .tooltip {
    visibility: hidden;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    top: 105%; /* Position the tooltip above the button */
    left: 50%;
    margin-left: -30px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .buttonBreath:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  .buttonChain:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  .buttonPomodoro:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  .buttonWeather:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  .buttonTask:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  .buttonDueDate:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }

  .buttonRedLine:hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
  
  .buttonNewPomodoro {
    border: 1px solid #FF6347;
    background: rgba(255, 99, 71, 0.10);
    margin-left: 85rem;
    margin-top: -90px;
}
.buttonNewPomodoro:hover {
    background: rgba(255, 99, 71, 0.2);
    border-color: #ff4500;
}

.buttonFocus {
  position: relative;
  margin-top: -70px;
  margin-left: 80rem;
  width: 10rem;
  height: 5rem;
  border-radius: 0.5rem;
  border: 1px solid #FF6347;
  background: rgba(255, 99, 71, 0.10);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  cursor: default; /* Prevent the cursor from changing to a pointer */
}
.buttonFocus:hover {
  background: rgba(255, 99, 71, 0.2);
  border-color: #ff4500;
}

.buttonStart, .buttonStop {
  width: 5rem;
  height: 2rem;
  border-radius: 0.3rem;
  border: 1px solid #FF6347;
  background: rgba(255, 99, 71, 0.10);
}

.buttonStart:hover, .buttonStop:hover {
  background: rgba(255, 99, 71, 0.2);
  border-color: #ff4500;
}

.pomodoro {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.timer {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}
