.weatherHome {
    background: url('../rectangles2/weatherhome.png');
    box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.25);
    border-radius: 13px;
    width: 25rem;
    height: 30rem;
    position: absolute;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 20px;
    color: #fff;
    margin-top: -18px;
    margin-left: 800px;
  }

  .weatherHome::before {
    content: '';
    position: absolute;
    border-radius: 13px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5; /* Adjust this value to get the desired darkness */
    z-index: 5;
}

.titleRectangle {
    background: linear-gradient(180deg, rgba(108, 93, 211, 0.8) 0%, rgba(57, 39, 105, 0.8) 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    width: 10.8125rem;
    height: 3.6875rem;
    margin-left: -20px; /* Adjust this value to move the rectangle further to the left */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 1.5625rem;
    margin-top: 10px;
    margin-left: -170px;
    z-index: 12;
  }
  
  .rectangle {
    z-index: 12;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    border-radius: 13px;
    width: 21.875rem;
    height: 5.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 21px;
    margin-top: 20px;
    padding-left: 20px;
  }

  .currentLocation {
    position: absolute;
    top: 20px; /* adjust this value as per your design */
    right: 20px; /* adjust this value as per your design */
    z-index: 15; /* make sure this is higher than .weatherHome::before's z-index */
}

  .dateTime {
    z-index: 12;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 18px;
    color: #fff;
    margin-bottom: 10px;
  }

  .weatherData {
    z-index: 12;
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
  
  .weatherItem {
    z-index: 12;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 5.27294rem;
    height: 6.6875rem;
    background: rgba(255, 255, 255, 0.25);
    border-radius: 4px; 
  }

  .weatherItem:not(:last-child) {
    margin-right: 10px;  /* adjust this value to increase or decrease space */
}

.refreshIcon {
  position: absolute;
  right: 25px;  
  top: 170px;  
  z-index: 15; 
  color: #fff; 
  font-size: 16px; 
}
  
  .weatherEmoji {
    z-index: 12;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .weatherDegree {
    z-index: 12;
    font-size: 0.875rem;
    font-weight: bold;

  }
  
  .weatherHour {
    z-index: 12;
    font-size: 0.875rem;
    color: #fff;
  }
  

